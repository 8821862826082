import React, { useState } from "react";
import styled from "styled-components";
import ExperienceScene from "./ExperienceScene";
import '../../css/App.css'
import Form from "./Form/Form";
import { CoolerMenu } from "../Text/Menu";

const SceneContainer = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    overflow-y: hidden;
    overflow-x: hidden;
    background: black;
`

export default function Experience(props)
{

    const [formClosed, setFormClosed] = useState(false)

    return(
        <>
            <CoolerMenu currentPage="#i1" phase = {0}/>
            <SceneContainer>
                <Form formClosed={formClosed} setFormClosed={setFormClosed}/>
                <ExperienceScene 
                    formClosed = {formClosed} 
                    setFormClosed = {setFormClosed} 
                    graphics = {2}
                />
            </SceneContainer>
        </>
    )
}