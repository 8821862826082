

export function handleHover({e, isMobile, r, elapsed, setHovered, props, HoverOut})
{
    if(isMobile) return;
    if(!r.current) return;
    if(elapsed.current < 4) return;
    if(r.current.material.uniforms.u_opacity.value < .2) return;

    e.stopPropagation();

    setHovered(true)
    props.setHovered(true)

    if(HoverOut.current)
    {
        HoverOut.current.restart();
    }   
}