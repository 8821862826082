import gsap from "gsap";

export default function Create_Section2_Animations(props)
{
    gsap.timeline({
        scrollTrigger: {
        trigger: "#CanvasContainer",
        pin: true,
        pinSpacing: true,
        scrub: 1,
        start: "top top",
        end: "bottom -500% "}
    })
    .to("#CanvasContainer", {opacity: 1}).timeScale(4)
    .to("#S2Canvas", {opacity: 0.6}, "<")
    .fromTo("#SCContainer", {x: 400}, {x: 0, opacity: 1})
    .from("#SCContainer", {height: '20vh'})
    .to("#SectionContainer", {background: "#111011"}, "<")
    .to("#SCCHeader", {opacity: 1})
    .to("#SCCParagraph", {opacity: 1, position: "relative"}, "<")
    .to("#SCCOptionsContainer", {opacity: 1}, "<")
    .to("#CanvasContainer", {opacity:0})

    
}