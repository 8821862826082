export const TokenQuestions = [
    {
        title: [<p>&nbsp;How is the HoneyBadger token used within the ecosystem? <i style={{color: 'yellow'}}>&nbsp;&nbsp;&nbsp;&nbsp;basic</i></p>],
        content: [
            `The HoneyBadger token plays a central role in the governance and functionality 
            of the ecosystem. At a high level, its primary purpose is to act as a unifying force 
            that supports projects leveraging the HoneyBadger framework while enabling user-driven 
            governance of the ecosystem.`,
            <br/>, <br/>,
            <b>Token Grants &nbsp;</b>, 
            `A pool of 1,000,000 tokens is allocated to support promising projects that use the HoneyBadger system. 
            These grants allow project owners to engage the wider community and offset system costs. 
            Each grant represents 1/100 of the remaining pool, ensuring the grant system can operate indefinitely. 
            For example, the first grant will be 10,000 tokens, the second 9,900 tokens, and so on.`,
            <br/>, <br/>,
            <b>Governance &nbsp;</b>, 
            `The HoneyBadger token empowers the community to participate in decentralized governance, 
            allowing stakeholders to make key decisions about the future direction of the ecosystem. 
            Examples include approving updates to the framework and allocating resources for system enhancements.`,
            <br/>, <br/>,
            <b>Staking &nbsp;</b>, 
            `The HoneyBadger token includes a highly efficient staking model at launch, inspired by the MEGABonk 
            Token staking system. By leveraging advanced low-level optimization techniques, the HoneyBadger staking 
            model achieves approximately 4x greater efficiency than its predecessor for key operations.`,
            <br/>, <br/>,
            <b>Utility in Future HoneyBadger Systems and Services &nbsp;</b>, 
            `The HoneyBadger token is built on a proxy-delegate model to allow seamless integration of future 
            logic and functionality. While our immediate priority is to finalize and launch the core HoneyBadger 
            framework, the token will also serve as a cornerstone for a broader ecosystem of services planned under 
            the HoneyBadger umbrella, further enhancing its utility over time.`
        ],
    },
    {
        title: [<p>&nbsp;How does HoneyBadger Token staking work? <i style={{color: 'yellow'}}>&nbsp;&nbsp;&nbsp;&nbsp;basic</i></p>],
        content: [
            `HoneyBadger token staking is simple and rewarding. When a user stakes to the 
            pool, 15% of their principle is distributed proportionally to all current stakers.  
            When unstaking, the same fee is applied to the remaining principal (does not touch 
            dividends—22.5% total fee).`,
            <br/>, <br/>,
            <b>Transfer Fees Go to the Staking Pool &nbsp;</b>, 
            `The HoneyBadger team can set up to a 5% transfer fee on the token. These fees 
            are distributed to stakers as dividends.`,
            <br/>, <br/>,
            <b>Stake Boost &nbsp;</b>, 
            `750,000 tokens are allocated to boosting staking dividends. When enabled 
            by the HoneyBadger team, this 
            feature adds a 0-40% boost on staking dividends created during that time, making the pool even more 
            rewarding for users.`,
            <br/>, <br/>
        ],
    }
];