import { useFrame, useThree } from "@react-three/fiber";
import { useRef } from "react";
import MatrixRainingLetters from "./MatrixEffect2/MatrixEffect2";
import InfoScene from "../Scene/InfoScene/InfoScene";

/*
            <LavaLampBackground position = {[0, 0, 4.95]}/>
                        <Roadmap2SceneGeneric vis = {true}/>
                                    <HoneyBadgerShiny questSection = {3}/>
*/

export default function TestSpace()
{
    return(
        <div style = {{width: '100vw', height: '100vh'}}>
            <InfoScene />
        </div>
    )
}

