import '../../css/App.css';
import '../../css/marquee.css';
import '../../css/TextStyles.css';
import '../../css/TitleTextStyles.css';

import { gsap } from 'gsap/gsap-core'
import { SplitText } from 'gsap/SplitText';
import { MotionPathPlugin } from 'gsap/all';
import { useEffect, useRef, useState } from 'react'

export default function TitleText(props)
{
  gsap.registerPlugin(SplitText)
  gsap.registerPlugin(MotionPathPlugin)

  const [titleSplitText, setTitleSplitText] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const TL = useRef(gsap.timeline({}));

  useEffect(() => {
    if(!titleSplitText)
    {
      setTitleSplitText(new SplitText(".fr", { type: "chars" }));
    }
  }, [])

  useEffect(() => 
  {
    if(!titleSplitText) return;

    TL.current.from(titleSplitText.chars, { x: 20, opacity: 0, duration: .75, stagger: 0.1, delay: .75 })
      .from(".ab", {y: 10, opacity: 0, duration: .75});

    setLoaded(true);

  }, [titleSplitText])


    useEffect(() =>
    {
      if(loaded !== true) return;
      if(TL.current.isActive()) return;

      if(props.hovered == true) HoverAnimations();
      else HoverOutAnimations();

    }, [props.hovered, loaded])


  return(
    <>
      <div className = "TextContainer">
          <h2 className = {"PS fr HomeTitle"}>HONEYBADGER</h2>
          <p className = {"inter ab Tagline"}>
            <b>Hunting Solidity's Toughest Predators:&nbsp;</b> 
            Scalability, Security, and Expenses
          </p>
          <p className = {"titleText ab2 DenText"}>
          🖱️ SCROLL TO ENTER THE DEN
          </p>
      </div>
    </>
  )
}

const HoverAnimations = () => 
{
  gsap.killTweensOf(".ab");
  gsap.killTweensOf(".ab2");

  gsap.to(".ab", {opacity: 0, position: "absolute", duration: 0})
  gsap.to(".ab2", {opacity: 1, duration: .5})
}

const HoverOutAnimations = () => 
{
  gsap.killTweensOf(".ab");
  gsap.killTweensOf(".ab2");

  gsap.to(".ab2", {opacity: 0})
  gsap.to(".ab", {opacity: 1, position: "relative"})
}
