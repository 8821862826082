import styles from '../CSS/Section2.module.css'
import { Canvas } from '@react-three/fiber';
import Wormhole from '../../../TestSpace/Wormhole/Wormhole';
import '../../../../css/TextStyles.css'
import arrow2 from '../../../../assets/arrow2.png'
import { useState } from 'react';

const FeatureSections = [
    'Pre-Built Framework',
    'Extendable Storage',
    'Permission Management',
    'Powerful Functions',
]

const FeatureDescriptions = [
    `HoneyBadger eliminates the need to build core infrastructure from scratch, 
    while exclusive features including dynamic storage architecture and built-in permission 
    management systems simplify and enhance the development of business logic. 
    <br/><br/><br/>
    <i>Experience the thrill of seeing your big ideas take shape, while we smooth out the friction 
    that makes smart contract development particularly challenging.</i>`,
    
    `Extendable Storage allows you to add new data structures and extend mapped datasets after deployment, 
    giving your system the flexibility needed to evolve gracefully -- without complex redeployments.

    <br/><br/>
    <i>Enjoy the confidence that comes from knowing your project can grow organically, 
    adapting smoothly to whatever the future brings.</i>`,

    `HoneyBadger’s Permission Management system lets you safely limit administrator privileges
     while granting just the right level of access to other contracts.
    <br/><br/>

    <i>Enjoy the freedom to move fast, knowing your security framework is rock-solid from day one.</i>`,

    `HoneyBadger’s gas-efficient storage functions reduce transaction costs, 
    making large, data-heavy operations affordable and efficient
    
    <br/><br/>
    <i>
    Appreciate the ease of building cost-effective systems, while knowing how challenging 
    it can be to manage transaction costs without these exclusive optimization tools.</i>`,
]

export default function Section2_HTML()
{
    const [currentOption, setOption] = useState(0);

    const NextFeature = () =>
    {
        if(currentOption + 1 === FeatureSections.length) return FeatureSections[0];
        return FeatureSections[currentOption + 1]
    }

    const NextSection = () =>
    {
        if(currentOption + 1 === FeatureDescriptions.length) return FeatureDescriptions[0];
        return FeatureDescriptions[currentOption + 1]
    }

    return(
        <div id = "SectionContainer" className = {styles.SectionContainer}>
            <div id = "CanvasContainer" className={styles.CanvasContainer}>

                <Canvas 
                    id = "S2Canvas" 
                    className={styles.CanvasStyles}
                    style = {{position: "absolute"}}
                >
                    <Wormhole />
                </Canvas>

                <div className={styles.S2Container}>
                    <h2 className='titleText' id = "S2DescText">
                        Discover a <a className='ShinyText'>New Dimension</a> of Possibilities for Your
                        Solidity Project
                    </h2>
                </div>

                <div className={`${styles.ShowcaseContainer} glass`} id = "SCContainer">

                    <h2 className= {`titleText ${styles.SCCH2}`} id = "SCCHeader">
                        Key Feature: <i style = {{color: "#cccccc"}}> &nbsp;{FeatureSections[currentOption]}</i>
                    </h2>

                    <p
                        id="SCCParagraph"
                        className='opensans'
                        dangerouslySetInnerHTML={{ __html: FeatureDescriptions[currentOption] }}
                    />

                    <div 
                    onClick={() =>
                    {
                        if(currentOption === FeatureSections.length - 1)
                        {
                            setOption(0);
                            return;
                        }
                        setOption(currentOption + 1);
                    }}
                    className={styles.OptionsContainer} id = "SCCOptionsContainer">

                        <h2 className={`orbitron ${styles.OCH2}`} id = "SCCHeader2">{`Next Feature: `} 
                            <i > &nbsp;{`${NextFeature()}`}</i>
                        </h2>
                        <img src = {arrow2} />

                    </div>
                </div>
            </div> 
        </div>
    )
}