import { Menu } from "../Text/Menu";
import PageManager from "./Pages/PageManager";
import Sidebar from "./Sidebar/Sidebar";
import { useEffect,  useState } from "react";
import '../../index.css'
import { AspectManager2, HFI } from "../Helpers/ClipManager";
import '../../css/docPage.css'
import { Canvas } from "@react-three/fiber";
import LavaLampBackground from "../TestSpace/LavaLampBackground";
import SignIn from "../SignIn/signIn";

export default function DocumentationPage(props)
{
    const [dims, setDimsChanged] = useState(false)
    const [page, setPage] = useState(1.1)
    const [docked, setDocked] = useState(false)
    const [height, setHeight] = useState(AspectManager2(1200))
    const PASS_ENABLED = true;

    const [user, setUser] = useState(null)

    useEffect(() =>
    {
      if(!user) return;

    //document.body.classList.add('no-scroll');
      document.body.style.overflowY = 'scroll';
      const handleResize = () =>
      {
        setDimsChanged(prev => !prev)
      }
  
      window.addEventListener('resize', handleResize);
  
      return () => 
      {
        window.removeEventListener('resize', handleResize)
        document.body.style.overflowY = '';
      }
      
    }, [dims, user])

    const handleSignIn = (user) => {
      setUser(user);
    };
  
    if (!user && PASS_ENABLED === true) 
    {
      return <SignIn onSignIn={handleSignIn} floatmode = {false}/>;
    }

    return(
        <>
            <div style = {{background: "none", width: "100vw", height: "100vh"}} >
              <Canvas style = {{width: "100vw", height: "100vh", position: "fixed"}}>
                <color attach = "background" args = {["black"]} />
                <LavaLampBackground position = {[0,0,-5]} scale = {50}/>
              </Canvas>
                <Sidebar page = {page} setPage = {(v) => setPage(v)} docked = {docked} setDocked = {(v) => setDocked(v)}
                    height = {height} setHeight = {(v) => {setHeight(v)}}
                />
                <PageManager page = {page} docked = {docked}/>
            </div>
        </>
    )
}