// App.js
import "./index.css";
import gsap from "gsap";
import SignIn from "./components/SignIn/signIn";
import QuestSystem from "./QuestSystem";
import { isDesktop } from "react-device-detect";
import { CoolerMenu } from "./components/Text/Menu";
import TopMenu from "./components/Text/TopMenu/TopMenu";
import React, { useEffect, useRef, useState } from "react";
import LowerScrollSection from "./components/LowerScrollSection/LowerScrollSection";
import { InfoSectionManager } from "./components/Text/TopMenu/InfoSectionManager/InfoSectionManager";
import AirdropForm from "./components/Text/AirdropForm/AirdropForm";


function App({airdropOpen}) 
{
  const sceneRef = useRef();
  const [sceneInView, setSceneInView] = useState();
  const [dimsChanged, setDimsChanged] = useState(false);
  const [topMenuSection, setTopMenuSection] = useState(0);
  const [user, setUser] = useState((false) ? "aba" : null);
  const [airdropForm, setAirdropOpen] = useState(airdropOpen);

  function handleSignIn(user)
  {
    setUser(user);
  }

  useEffect(() =>
  {
    if(!sceneRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        let intersecting = entry.isIntersecting;
        if(sceneInView !== intersecting)
        {
          setSceneInView(intersecting);
        }
      }, {threshold: .1}
    );

    observer.observe(sceneRef.current);

    return () => {
      if (sceneRef.current) {
          observer.unobserve(sceneRef.current);
      }
    }

  }, [sceneRef.current, sceneInView, user]);


  useEffect(() => 
  {
      if(!user) return;
      gsap.to(".noPreload", { visibility: "visible", opacity: 1 });

      const handleResize = () => {
        setDimsChanged((prev) => !prev);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        document.body.style.overflowY = "";
      };
  }, [dimsChanged, user]);

  //if (!user) return <SignIn onSignIn={handleSignIn}/>;

  
  return (
    <>
      {!user &&
        <SignIn onSignIn={handleSignIn} floatMode={true}/>
      }
      <AirdropForm open = {airdropForm} setAirdropOpen={setAirdropOpen}/>
      <TopMenu 
        airdropForm = {airdropForm}
        setAirdropOpen = {setAirdropOpen}
        user = {user}
        topMenuSection = {topMenuSection} 
        setTopMenuSection = {setTopMenuSection}/>

      <InfoSectionManager 
        setTopMenuSection = {setTopMenuSection}
        topMenuSection = {topMenuSection}/>
      <CoolerMenu currentPage="#i1" phase = {0}/>
      <div 

        style={{ height: "100vh"}}
      >
        <QuestSystem />
      </div>
      {isDesktop && user && window.innerWidth > 800 && 
        <LowerScrollSection />
      }
      
    </>
  );
}



export default App;