import styles from '../InfoSections/InfoSectionScaffold.module.css';
import Faq from 'react-faq-component';
import { faqConfig, faqData, faqStyles } from './FAQData/FaqData';

import Roadmap from './Roadmap/Roadmap.svg';
import TokenomicsTextcontent from './Tokenomics/TokenomicsTextContent';
import HowItWorksText from './HowItWorks/HowItWorksText';

export const UniqueData = {
    Section1: <HowItWorksText />,
    Section2:
    <div style = {{pointerEvents: 'all', alignSelf: 'center', justifySelf: 'center',
    overflowY: 'scroll'}}>
        <Faq 
            data = {faqData}
            styles = {faqStyles}
            config = {faqConfig}
        />
    </div>,
    Section3:         
    <div style = {{pointerEvents: 'all', alignSelf: 'center', justifySelf: 'center',
        overflowY: 'scroll'}}>
            <img src = {Roadmap} 
            style = {{width: "100%"}}
            alt = "tokenomics pie chart"/>
        </div>,
    Section4: <TokenomicsTextcontent />
}