import '../../InfoSections/InfoSectionScaffold.module.css';
import { GeneralQuestions } from './GeneralQuestions';
import { TokenQuestions } from './TokenQuestions';
import {TechnicalQuestions} from './TechnicalQuestions'

export const dubBreak = [<br/>,<br/>]

export const faqData = {
    title: [
        (
        <div>
            <p><b style = {{fontSize: '40px'}}>
                &nbsp;Frequently-Asked Questions
            </b></p>
        </div>
        )],
    rows: [
        ...GeneralQuestions,
        ...TokenQuestions,
        ...TechnicalQuestions,
    ],
};

export const faqStyles = {
    bgColor: 'none',
    titleTextColor: "white",
    rowTitleColor: "#cccc",
    rowContentColor: 'white',
    arrowColor: "grey",
};

export const faqConfig = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true
};
