import gsap from "gsap";
import '../../../../css/TextStyles.css';
import { useEffect, useState } from "react";
import styles from './InfoSectionScaffold.module.css';
import InfoScene from "../../../Scene/InfoScene/InfoScene";

export default function InfoSectionScaffold(props)
{
    const [reveal, setReveal] = useState(false);
    let trueId = `InfoSectionScaffold${props.id}`;
    let trueIdTextContainer = `IntroSectionTextContainer${props.id}`;
    let trueIdExit = `IntroSectionExit${props.id}`

    useEffect(() => 
    {
        if(reveal) return;

        const revealAnim = gsap.timeline({paused: true})
        .to(`#${trueId}`,  
            {opacity: 1, pointerEvents: 'all', zIndex: 100000})
        .to(`#${trueIdTextContainer}`, {opacity: 1, delay: 2})
        .to(`#${trueIdExit}`, {opacity: 1})
        setReveal(revealAnim);
    }, [])

    useEffect(() => {
        if(!reveal) return;
        if(props.topMenuSection > 0 && reveal.progress() !== 1)
        {
            reveal.timeScale(1).play();
        }
        if(props.topMenuSection === 0 && reveal.progress() !== 0)
        {
            reveal.timeScale(2).reverse();
        }

    }, [reveal, props.topMenuSection])


    return(
        <div className={styles.Container} id = {trueId}>
            <p id = {trueIdExit} className={styles.Exit} onClick = {() => {props.setTopMenuSection(0)}}><b>X</b></p>
            <div id = {trueIdTextContainer} className={`${styles.TextContainer} glass`}>
                {props.data}
            </div>
            <InfoScene topMenuSection = {props.topMenuSection} />
        </div>
    )
}