import { useEffect, useState } from "react"
import InfoSectionScaffold from "../InfoSections/InfoSectionScaffold";
import styles from '../InfoSections/InfoSectionScaffold.module.css';
import { UniqueData } from "./SectionData";

export const InfoSectionManager = (props) => {

    const [currentData, setCurrentData] = useState(<div />)

    useEffect(() => 
    {

        if(props.topMenuSection === 0 && currentData !== <div />)
        {
            setTimeout(() => {
                setCurrentData(<div />)
            }, 1000)
            
        }
        if(props.topMenuSection === 1) setCurrentData(UniqueData.Section1)
        if(props.topMenuSection === 2) setCurrentData(UniqueData.Section2);
        if(props.topMenuSection === 3) setCurrentData(UniqueData.Section3);
        if(props.topMenuSection === 4) setCurrentData(UniqueData.Section4);

    }, [props.topMenuSection])

    return(
        <>
            <InfoSectionScaffold 
                data = {currentData}
                setTopMenuSection = {props.setTopMenuSection}
                topMenuSection = {props.topMenuSection} 
                id = "HowItWorks"/>
        </>
    )

}