

export function handleHoverOut({e, isMobile, r, setHovered, props, HoverOut})
{
    if(isMobile) return;
    if(!r.current) return;
    if(r.current.material.opacity < .2) return;

    e.stopPropagation();
    setHovered(false);
    props.setHovered(false)

    if(HoverOut.current) HoverOut.current.reverse();
}
