import { useState, useEffect } from "react";
import axios from "axios";
import styles from "./airdropform.module.css";
import "../../../css/TextStyles.css";

import tglogo from "../../../assets/tglogo.png";
import Xlogo from "../../../assets/xlogo3.png";
import solLogo from "../../../assets/Solana_logo.png";
import logo from "../../../assets/logo512.png";
import { isDesktop } from "react-device-detect";

export default function AirdropForm({ open, setAirdropOpen }) {
  const [formData, setFormData] = useState({
    telegram: "",
    twitter: "",
    solana: "",
  });

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Check if user has already submitted (localStorage for persistence)
    const hasSubmitted = localStorage.getItem("hasSubmitted");
    if (hasSubmitted) {
      setSubmitted(true);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if all fields are filled
    if (!formData.telegram || !formData.twitter || !formData.solana) {
      alert("All fields are required!");
      return;
    }

    if(submitted)
    {
        alert("Cannot submit again during this session!");
        return;
    }

    try {
      // Send form data to AWS API Gateway
      const response = await axios.post(
        "https://527hnxjel8.execute-api.us-west-1.amazonaws.com/Prod/submit", // Replace with your API Gateway URL
        formData
      );

      // Mark as submitted in localStorage
      localStorage.setItem("hasSubmitted", "true");
      setSubmitted(true);

      alert("Form Submitted Successfully!");
      console.log("AWS Response:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  if(!open) return null;

  return (
    <div className={`${styles.Container}`} onClick={() => setAirdropOpen(false)}>
      <form
        onClick={(e) => e.stopPropagation()}
        className={`${styles.FormContainer}`}
        onSubmit={handleSubmit}
      >
        <div className={styles.FormElementPosition}>
          <h2 className="Montserrat" style={{ padding: 0, margin: 0, color: "white" }}>
            HoneyBadger Airdrop - Session 1
          </h2>
          <ul>
            <li>Total: 5,000 tokens (Solana) | Total Supply: 10,000,000</li>
            <li>Future events will release 1,000 tokens.</li>
            <li>Must be a Twitter follower and Telegram member to be eligible.</li>
            <li>
              Airdrop tokens will be redeemable for the official token when it
              launches, with a randomized (per user) 30-90 day vesting period.
            </li>
            <li>Our Telegram is currently invite-only: we will add you.</li>
          </ul>

          <label className={styles.FL} htmlFor="telegram">
            <img className={styles.IMG} src={tglogo} alt="telegram logo" />
            TELEGRAM USERNAME
          </label>
          <input
            type="text"
            id="telegram"
            className={styles.Input}
            name="telegram"
            maxLength="20"
            value={formData.telegram}
            onChange={handleChange}
          />

          <label className={styles.FL} htmlFor="twitter">
            <img style={{ marginTop: "2px" }} className={styles.IMG} src={Xlogo} alt="x.com logo" />
            X.COM USERNAME
          </label>
          <input
            type="text"
            id="twitter"
            name="twitter"
            className={styles.Input}
            maxLength="40"
            value={formData.twitter}
            onChange={handleChange}
          />

          <label className={styles.FL} htmlFor="solana">
            <img
              style={{ width: "15px", height: "15px" }}
              className={styles.IMG}
              src={solLogo}
              alt="Solana logo"
            />
            SOLANA ADDRESS
          </label>
          <input
            type="text"
            id="solana"
            className={styles.Input}
            name="solana"
            maxLength="50"
            value={formData.solana}
            onChange={handleChange}
          />

          {submitted !== true && <input className={styles.Submit} type="submit" value="🚀 Submit" />}
          {submitted && <p style = {{color: 'red'}} className = {styles.FL}>Already submitted for this session!</p>}
        </div>

        {isDesktop && <img src={logo} style={{ width: "30%", height: "30%" }} />}
      </form>
    </div>
  );
}