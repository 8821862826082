

export function handleBadgerUniformChangeOnHover(hovered, r, r2, delta)
{
    if(hovered)
        {
            r.current.material.uniforms.time.value = r.current.material.uniforms.time.value + delta;
            r2.current.material.uniforms.time.value = r2.current.material.uniforms.time.value + delta;

            if(r.current.material.uniforms.u_transitionTime.value < 1.)
            {
                r.current.material.uniforms.u_transitionTime.value = r.current.material.uniforms.u_transitionTime.value + delta * .5;
                r2.current.material.uniforms.u_transitionTime.value = r2.current.material.uniforms.u_transitionTime.value + delta * .5;
            }
        }
        else
        {
            if(r.current.material.uniforms.u_transitionTime.value > 0)
            {
                r.current.material.uniforms.time.value = r.current.material.uniforms.time.value + delta;
                r2.current.material.uniforms.time.value = r2.current.material.uniforms.time.value + delta;
    
                r.current.material.uniforms.u_transitionTime.value = r.current.material.uniforms.u_transitionTime.value - delta * .5;
                r2.current.material.uniforms.u_transitionTime.value = r2.current.material.uniforms.u_transitionTime.value - delta * .5;
            }
        }
}