import { OrbitControls } from "@react-three/drei";
import StarsBg from "./StarsBg";
import { Canvas, useThree } from "@react-three/fiber";
import { HoneyBadgerModelBarebones2, HoneyBadgerSpaceman2 } from "../../Models/HoneyBadgerModel";
import { Moon } from "./Moon";
import { useEffect, useState } from "react";
import gsap from "gsap";

//props.shown
export default function InfoScene(props)
{
    return(
        <Canvas id = "InfoCanvas1" 
        style = {{
            width: '100%', 
            height: '100%', 
            position: 'absolute'
        }}>
            <OrbitControls enableZoom = {false} />

            <pointLight 
                intensity = {200} 
                position = {[-6, 4, 0]} 
                distance={50}/>
            
            <CameraZoom 
                topMenuSection = {props.topMenuSection}
            />

            <HoneyBadgerSpaceman2 position = {[0, 0, 3.5]} />
            <color attach = "background" args = {['#12131f']} />
            <StarsBg total={2000}/>
        </Canvas>
    )

}

const CameraZoom = (props) => 
{
    const {camera} = useThree();
    const [timeline, setTl] = useState(null);

    useEffect(() => {
        if(!camera) return;
        if(timeline) return;

        let tl = gsap.timeline({paused: true})
        .fromTo("#InfoCanvas1", {opacity: 0}, {opacity: 1, duration: 1})
        .fromTo(camera.position, {z: 500}, {z: 5, duration: 1.5,}, "<");

        setTl(tl);
    }, [])

    useEffect(() => {
        if(!timeline) return;
        
        if(props.topMenuSection > 0 && timeline.progress() < 1)
        {
            timeline.pause();
            timeline.play();
        }
        if(props.topMenuSection === 0 && timeline.progress() != 0)
        {
            timeline.pause();
            timeline.reverse();
        }

    }, [props.topMenuSection])
}