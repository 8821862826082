import { useEffect, useMemo, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Object3D } from "three";

export default function StarsBg({ total = 2000 }) 
{
    const {camera} = useThree();
    const ref = useRef();
    const dummy = useMemo(() => new Object3D, []);

    camera.far = 3000;
    camera.updateProjectionMatrix();

    useEffect(() => {
        let count = 0;

        for(count; count < total; count++)
        {
            dummy.position.set(
                (Math.random() > 0.5 ? 1:-1) * Math.random() * 2000, 
                (Math.random() > 0.5 ? 1:-1) * Math.random() * 1000, 
                (Math.random() > 0.5 ? 1:-1) * Math.random() * 1000)
            dummy.updateMatrix()
            ref.current.setMatrixAt(count, dummy.matrix)
        }
    }, [])

    useFrame((_, delta) => {
        if(!ref.current) return;

        ref.current.rotation.y += delta * .02;
    })

    return(
        <instancedMesh 
        ref = {ref} 
        args = {[null, null, (total )]} 
        frustumCulled = {false}>
            <sphereGeometry args = {[1,1,1,1]} />
            <meshBasicMaterial color = "white"/>
        </instancedMesh>
    )
}