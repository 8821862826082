import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useEffect } from "react";
import { useStoryBrandSectionContext } from "../StoryBrandSectionWrapper";

gsap.registerPlugin(ScrollTrigger);

export const Create_Section3_Animations = () => {
    const { neoGlassesRef, shinyBadgerRef } = useStoryBrandSectionContext();


    useEffect(() => {
        if (!neoGlassesRef.current || !shinyBadgerRef.current) {
            // If neoGlassesRef isn't ready, set up an interval to check for it
            const intervalId = setInterval(() => {
                if (neoGlassesRef.current && shinyBadgerRef.current) {
                    // Clear the interval once ref is available
                    clearInterval(intervalId);

                    const targetPosition = shinyBadgerRef.current.position.clone();

                    // Trigger GSAP animation now that ref is ready
                    gsap.timeline({
                        scrollTrigger: {
                            trigger: "#S3Container",
                            pin: true,
                            pinSpacing: true,
                            scrub: 1,
                            start: "top top",
                            end: "bottom -200%",
                        },
                    })
                    .to("#S3Container", { opacity: 1 })
                    .fromTo("#MatrixContainer", { opacity: 0 }, { opacity: 0.4 })
                    .fromTo(shinyBadgerRef.current.position, {x: targetPosition.x + 20}, {x: targetPosition.x})
                    .fromTo(neoGlassesRef.current.position, { y: 10.35 }, { y: -2.35 })
                    .to(neoGlassesRef.current.position, {x: 6.5})
                    .fromTo("#S3InitialText", {opacity: 0}, {opacity: 1})
                    .to("#S3InnerTC", {marginTop: "0vh"}, "<")
                    .fromTo("#S3ButtonsContainer", {opacity: 0}, {opacity: 1})
                }
            }, 100); // Check every 100ms

            // Clean up interval on unmount
            return () => clearInterval(intervalId);
        }
    }, [neoGlassesRef, shinyBadgerRef]); // Watch neoGlassesRef for changes

    return null;
}