import gsap from "gsap";

export default function playAmbientAnimation(
    hasHovered, ref, startPos, endPos, setAmbientA
)
{
    if(hasHovered !== true && ref.current && startPos && endPos)
    {
        let ambientAnimation = gsap.timeline({paused: true, repeat: -1, delay: 2})
        .to(ref.current.position, {x: startPos, duration: 6})
        .to(ref.current.rotation, {y: Math.PI / 2, duration: .2}, "<")
        .to(ref.current.position, {x: endPos, duration: 6})
        .to(ref.current.rotation, {y: -Math.PI / 2, duration: .2}, "<")
        .play();

        setAmbientA(ambientAnimation)
    }
}