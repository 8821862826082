import { ShaderMaterial } from "three";
import { vertexShader, fragmentShader } from "../../shadershb";

export const shaderMaterialHoneyBadger = (isBack) => {
    return new ShaderMaterial(
        {
            extensions: 
            {
                derivatives: "#extension GL_OES_standard_derivatives : enable"
            },
            uniforms: {
                time: {value: 0},
                u_hovered: {value: 0.},
                u_back: {value: isBack ? 1 : 0.},
                u_transitionTime: {value: 0.},
                u_opacity: {value: 0.}
            },
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            wireframe: false,
            transparent: true
        });
}
