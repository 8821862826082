export const TechnicalQuestions = [
    {
        title: [<p>&nbsp;Why use HoneyBadger instead of proxy-delegate? <i style={{color: 'yellow'}}>&nbsp;&nbsp;&nbsp;&nbsp;technical</i></p>],
        content: [`While proxy-delegate models offer a robust solution 
            for functional upgradeability, comparing them to HoneyBadger is like 
            comparing a hammer to an entire toolbox. HoneyBadger goes 
            far beyond upgradeability—it's a comprehensive framework that simplifies 
            the development of state-of-the-art smart contract systems. 
            By integrating game-changing features including robust permission management, decentralized governance, 
            seamless scalability via fluid data structures, modular add-ons, 
            and groundbreaking gas efficiency, HoneyBadger delivers everything 
            developers need to innovate faster and with greater confidence, 
            while streamlining the scope of development instead of expanding it.`],
    },
    {
        title: [<p>&nbsp;What are the benefits of HoneyBadger's unique fluid storage architecture? <i style={{color: 'yellow'}}>&nbsp;&nbsp;&nbsp;&nbsp;technical</i></p>],
        content: [`HoneyBadger’s fluid storage architecture is a groundbreaking innovation that addresses several critical 
            challenges in smart contract systems, offering the following key benefits:`,
        <br/>,<br/>,
        <b>Unlimited Scalability Without Redeployment: &nbsp;</b>,
        `Traditional smart contracts rely on fixed storage layouts and structures, which 
        limit their ability to adapt or scale over time. HoneyBadger’s fluid 
        storage architecture allows dynamic expansion and modification of 
        data structures without requiring contract redeployment. This means 
        developers can extend functionality seamlessly, keeping systems competitive 
        in a rapidly evolving blockchain environment.`,
        <br/>,<br/>,
        <b>Improved Efficiency and Gas Optimization: &nbsp;</b>,
        `HoneyBadger's storage system is designed to minimize expensive `, <i>sstore </i>, 
        `and `, <i>sload </i>, `operations, significantly reducing costs without 
        added effort (especially when coupled with batch operations). `,
        <br/>,<br/>,
        <b>Seamless Interoperability: </b>,
        `All contracts in a HoneyBadger ecosystem are linked at the storage layer,
        providing unrivalled interoperability.  New contracts can seamlessly integrate 
        with legacy code, while optional add-on modules maintain system integrity by 
        imposing boundaries.`],
    },
    {
        title: [<p>&nbsp;How does HoneyBadger ensure high gas efficiency? <i style={{color: 'yellow'}}>&nbsp;&nbsp;&nbsp;&nbsp;technical</i></p>],
        content: [`HoneyBadger ensures high gas efficiency by leveraging assembly-level optimizations, 
        novel batching techniques, and efficient storage operations to minimize the costs of frequently 
        expensive blockchain transactions. Developers gain access to foundational functions that are finely tuned 
        to reduce execution overhead, resulting in faster and cheaper operations.`],
    },
    {
        title: [<p>&nbsp;Can HoneyBadger help reduce the costs of auditing and testing? <i style={{color: '#0A64BC'}}>&nbsp;&nbsp;&nbsp;&nbsp;basic</i></p>],
        content: [
            `Yes, HoneyBadger significantly reduces the costs associated with auditing and testing by addressing the root causes of complexity and inefficiency in smart contract systems:`,
            <br/>, <br/>,
            <b>Streamlined Development Through Pre-Built Infrastructure: &nbsp;</b>,
            `HoneyBadger provides powerful, ready-to-use infrastructure for storage and permission management. 
            This reduces the need for developers to write and test custom low-level implementations, 
            decreasing the scope and cost of audits.`,
            <br/>, <br/>,
            <b>Minimized Attack Surfaces: &nbsp;</b>,
            `By consolidating critical operations, such as storage routing and permission handling, into a single, secure contract, 
            HoneyBadger reduces the overall complexity of the system. A smaller, more focused attack surface means fewer 
            vulnerabilities to audit and test, lowering costs.`,
            <br/>, <br/>,
            <b>Gas-Optimized and Efficient Codebase: &nbsp;</b>,
            `HoneyBadger is built with assembly-level optimizations and best practices, ensuring that core operations 
            are both efficient and predictable. Auditors can focus on the modular additions or application-specific logic 
            instead of reexamining foundational components.`,
            <br/>, <br/>,
            <b>Decentralized Upgradeability Reduces Post-Deployment Risk: &nbsp;</b>,
            `HoneyBadger's governance modules enable incremental upgrades without redeployment, reducing the need for 
            extensive re-audits when making changes. This ensures cost savings throughout the lifecycle of the contract.`,
            <br/>, <br/>,
            <b>Compatibility With Established Standards: &nbsp;</b>,
            `As HoneyBadger is fully compatible with OpenZeppelin contracts, developers can leverage familiar, battle-tested standards. 
            This simplifies integration and allows audits to focus on specific innovations rather than the broader system.`,
        ],
    },
    {
        title: [<p>&nbsp;What industries or applications can benefit most from using HoneyBadger? <i style={{color: '#0A64BC'}}>&nbsp;&nbsp;&nbsp;&nbsp;basic</i></p>],
        content: [
        `HoneyBadger's advanced infrastructure is designed to address the needs of various industries and applications, making it highly versatile and impactful in the following areas:`,
        <br/>, <br/>,
        <b>Decentralized Finance (DeFi): &nbsp;</b>,
        `With its gas-efficient operations, secure permission management, and seamless scalability, HoneyBadger is 
        ideal for DeFi platforms. Applications such as lending protocols, decentralized exchanges, and stablecoin 
        systems can greatly benefit from its robust and cost-effective infrastructure.`,
        <br/>, <br/>,
        <b>Supply Chain Management: &nbsp;</b>,
        `HoneyBadger’s ability to manage and integrate multiple contracts at the storage layer makes it perfect for 
        supply chain applications. It facilitates real-time tracking, secure data sharing, and transparent record-keeping 
        across stakeholders.`,
        <br/>, <br/>,
        <b>Gaming and NFTs: &nbsp;</b>,
        `The system’s fluid storage architecture allows for dynamic scalability, making it well-suited for blockchain 
        games and NFT marketplaces. Developers can efficiently handle high transaction volumes and adapt to growing 
        user bases without redeployment.`,
        <br/>, <br/>,
        <b>Enterprise Solutions: &nbsp;</b>,
        `For industries such as finance, healthcare, and real estate, HoneyBadger provides the modular infrastructure 
        needed to build secure and scalable blockchain solutions. Its compatibility with governance modules ensures 
        compliance and adaptability in highly regulated environments.`,
        <br/>, <br/>,
        <b>Data Management Platforms: &nbsp;</b>,
        `HoneyBadger’s storage layer linking allows for efficient data routing and permissioned access, making it an 
        excellent choice for decentralized data platforms, identity management systems, and IoT networks.`,
    ],
    }
];