

export const GeneralQuestions = [
    {
        title: [<p>&nbsp;In simple terms, what is the HoneyBadger system? <i style={{color: '#0A64BC'}}>&nbsp;&nbsp;&nbsp;&nbsp;basic</i></p>],
        content: 
        `
            The HoneyBadger system is a powerful smart contract that manages 
            the permissions and data for a wider smart contract ecosystem.  
        `,
    },
    {
        title: [<p>&nbsp;How is HoneyBadger a "framework"? <i style={{color: '#0A64BC'}}>&nbsp;&nbsp;&nbsp;&nbsp;basic</i></p>],
        content:
            `HoneyBadger acts as the backbone of a smart contract system, routing data and permission-based 
            operations with advanced efficiency. It links the entire ecosystem, 
            enabling seamless integration and smooth updates while unlocking powerful capabilities for developers.`,
    },
    {
        title: [<p>&nbsp;What makes HoneyBadger different from other blockchain frameworks? <i style={{color: 'yellow'}}>&nbsp;&nbsp;&nbsp;&nbsp;technical</i></p>],
        content: [`HoneyBadger is a unique system, with its closest relative being 
            the OpenZeppelin family of contracts—although, notably, it is fully compatible 
            with OpenZeppelin. The term 'blockchain framework' is broad, often referring to 
            technologies like Arbitrum, which creates overarching environments for smart 
            contract systems, or Cosmos, which offers modular tools for building blockchains. 
            HoneyBadger, however, is more like React.js for Solidity development: a backend 
            infrastructure that enhances the capabilities of smart contract systems while 
            simplifying their implementation.  
         `, <br/>,<br/>,
        `Unlike OpenZeppelin, which provides pre-built functionality within specific contracts, 
        HoneyBadger operates as an independent contract. Operators deploy individual instances 
        that they fully control (and notably, are free to meticulously configure through 
        modular add-ons and delegation), each of which is completely separate from others. These contracts 
        are designed to route storage and permission operations through HoneyBadger, linking the 
        entire system at the storage layer while designating universal access to the powerful 
        functions provided by HoneyBadger. This approach ensures robust efficiency, powerful 
        capabilities, and secure permission management, all while maintaining decentralization and 
        scalability.`],
    },
    {
        title: [<p>&nbsp;What are the top three biggest problems that HoneyBadger solves? <i style={{color: 'yellow'}}>&nbsp;&nbsp;&nbsp;&nbsp;technical</i></p>],
        content: 
        [
            <b>Boundless Scalability Harmonized With Decentralization &nbsp;</b>,
            `Smart contract systems are notoriously rigid and difficult to extend after 
            deployment, which makes it difficult and expensive to stay competitive in the 
            rapidly-evolving market.  If developers don't build complicated governance systems, 
            upgradeability 
            requires giving administrators unchecked power, which ruins decentralization.  
            HoneyBadger solves both problems with its novel `, <i>fluid data structure </i>,
            `technology, which can be coupled with `, <i>governance modules </i>, `to provide 
            truly unlimited scalability that doesn't require redeployments or lost decentralization.
            Unlike alternatives such as OpenZeppelin, HoneyBadger plug-and-play and super gas efficient. `, 
            <br/>, <br/>,
            <b>Cheaper and Faster Development</b>,
            `
            Smart contract development is notoriously expensive and demanding. 
            Because code can't be modified after deployment, perfection is the
            only acceptable standard. This leads to slow, costly development cycles 
            and audits that grow even more expensive for complex systems—stifling innovation. 
            When vulnerabilities slip through, the consequences can be catastrophic.
            `,<br/>,<br/>,
            `HoneyBadger tackles these challenges head-on with powerful, ready-to-use 
            infrastructure. Developers can focus entirely on business logic, confident 
            in the strength and efficiency of their foundation. The result? A faster, cheaper, 
            and more refined product, primed for long-term success in a rapidly evolving market.`, 
            <br/>,<br/>,
            <b>Powerful Capabilities With High Efficiency </b>,
            `The exceedingly high costs and risks associated with smart contract systems 
            often lead to end products with `, <i>just enough </i>, `to get by.  
            Powerful capabilities such as batch storage and retrieval operations, 
            robust permission management systems, and fluid storage architecture 
            (a HoneyBadger original) provide a significant and rare advantage in the market.  
            HoneyBadger takes the competitive advantage a step further by providing highly-optimized implementations 
            for all of its features: the system is built in almost pure assembly and 
            utilizes novel optimization techniques that bring gas costs to near their possible 
            lower limits. ` 
        ],
    },
    {
        title: [<p>&nbsp;How can I start using HoneyBadger? <i style={{color: '#0A64BC'}}>&nbsp;&nbsp;&nbsp;&nbsp;basic</i></p>],
        content: `HoneyBadger is not yet available - while the system is already complete, 
        it needs to be audited. Given its assembly-heavy implementation and 
        inclusion of complicated optimization techniques, this will be expensive
        and time-consuming.  After release, the system will be available for ETH 
        or HoneyBadger Tokens.  Ownership is encoded in a deployment license NFT, which 
        allows users to deploy at their leisure.`,
    },
    {
        title: [<p>&nbsp;Is there a free version I can test out? <i style={{color: '#0A64BC'}}>&nbsp;&nbsp;&nbsp;&nbsp;basic</i></p>],
        content: `Yes, there is a free version!  The open-source model is very basic and 
        lacks almost any of our advanced features, but is still a great way to familiarize yourself 
        with the system and get a taste for how it is implemented.`,
    },
]
