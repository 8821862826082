

export const setStartEndPos = ({
    setStartPos,
    setEndPos
}) => 
{
    let res = window.innerWidth / window.innerHeight;

    if(res >= 2) 
    {
        setStartPos(40)
        setEndPos(-40)
    }
    else if(res >= 1.8)
    {
        setStartPos(35)
        setEndPos(-35)
    }
    else if(res >= 1.6)
    {
        setStartPos(35)
        setEndPos(-35)
    }
    else if(res >= 1.4)
    {
        setStartPos(33)
        setEndPos(-33)
    }
    else if(res >= 1.2)
    {
        setStartPos(28)
        setEndPos(-28)
    }
    else if(res >= 1.0)
    {
        setStartPos(15)
        setEndPos(-15)
    }
    else if(res >= .8)
    {
        setStartPos(12.5)
        setEndPos(-12.5)
    }
    else if(res >= .6)
    {
        setStartPos(11.5)
        setEndPos(-11.5)
    }
    else if(res >= .4)
    {
        setStartPos(10.5)
        setEndPos(-10.5)
    }
    else
    {
        setStartPos(9.5)
        setEndPos(-9.5)
    }
}