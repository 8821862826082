/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export function Moon(props) {
  const { nodes, materials } = useGLTF('/Moon.gltf')
    const ref = useRef();

    useFrame((_, delta) => {
        if(!ref.current) return;

        ref.current.rotation.y += delta * .075;
        ref.current.rotation.x += delta * .05;
    })

  return (
    <group 
        {...props} 
        dispose={null} >
      <mesh
        ref = {ref}
        castShadow
        receiveShadow
        geometry={nodes.Moon.geometry}
        material={materials.Moon}
        position={[-10,4,-5]}
        rotation={[-2.233, 0.82, 1.949]}
        scale={2}
      />
    </group>
  )
}

useGLTF.preload('/Moon.gltf')