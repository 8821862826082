import styles from './TopMenu.module.css';
import '../../../css/TextStyles.css'
import xlogo from '../../../assets/xlogo2.png';
import tglogo from '../../../assets/tglogo2.png';
import gsap from 'gsap';
import { useEffect, useState } from 'react';

export default function TopMenu(props)
{
    const width = window.innerWidth;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(!loaded)
        {
            gsap.fromTo("#TopMenuContainer", {y: -30, opacity: 0}, {y: 0, opacity: 1, delay: 5, onComplete: () => {setLoaded(true)}})
        }
    }, [])
    
    if(width > 550)
    {
        return(
            <div className={styles.Container} id = "TopMenuContainer">
    
                <img src = {xlogo} className={styles.Image}/>
                <img src = {tglogo} className={styles.Image}/>
                <h2 
                    onClick = {() => {props.setAirdropOpen(true)}} 
                    className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> 
                    <a className='ShinyText' >⚡ AIRDROP - Session 1⚡</a>
                </h2>
                <h2 onClick = {() => {props.user && props.setTopMenuSection(1)}} className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> [How it Works] </h2>
                <h2 onClick = {() => {props.user && props.setTopMenuSection(2)}} className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> [FAQ] </h2>
                <h2 onClick = {() => {props.user && props.setTopMenuSection(3)}} className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> [Roadmap] </h2>
                <h2 onClick = {() => {props.user && props.setTopMenuSection(4)}} className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> [Tokenomics] </h2>
                <h2 onClick = {() => {props.user && props.setTopMenuSection(5)}} className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> [Stake HoneyBadger Token] </h2>
            </div>
        )
    }
    else
    {
        return(
            <div className={styles.Container2} id = "TopMenuContainer">
                <div className={styles.ItemsContainer}>
                    <img src = {xlogo} className={styles.Image}/>
                    <img src = {tglogo} className={styles.Image}/>
                    <h2 onClick = {() => {props.user && props.setTopMenuSection(1)}} className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> [How it Works] </h2>
                    <h2 onClick = {() => {props.user && props.setTopMenuSection(2)}} className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> [FAQ] </h2>
                    <h2 onClick = {() => {props.user && props.setTopMenuSection(3)}} className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> [Roadmap] </h2>
                </div>
                
                <div className={styles.ItemsContainer}>
                    <h2 onClick = {() => {props.user && props.setTopMenuSection(4)}} className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> [Tokenomics] </h2>
                    <h2 onClick = {() => {props.user && props.setTopMenuSection(5)}} className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> [Stake HoneyBadger Token] </h2>
                    <h2 
                        onClick = {() => {props.setAirdropOpen(true)}} 
                        className={`${styles.Item} ${!props.user && styles.SignIn} opensans`}> 
                        <a className='ShinyText' >⚡ AIRDROP - Session 1⚡</a>
                </h2>
                </div>


            </div>
        )
    }

}